.creator-container {
  .creator-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .creator-card-container {
    margin-top: 2.4rem;

    .creator-dummy-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 25rem);

      .dummy-text {
        font-weight: 500;
        font-size: 2.4rem;
        letter-spacing: 0.02em;
        color: #B7B5C6;
      }
    }
  }
}
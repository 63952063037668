.detail-card-container {
  padding: 2.4rem;
  box-shadow: 0 10px 2rem rgba(149, 157, 165, 0.2);
  border-radius: 1.2rem;
  margin-top: 2.4rem;

  .cover-image-container {
    position: relative;
    width: 100%;

    .image {
      width: 100%;
      height: 100%;
      border-radius: 0.8rem;
    }

    .profile {
      position: absolute;
      height: 16rem;
      width: 16rem;
      bottom: -11rem;
      border: 2px solid rgba(203, 203, 203, 1);
      border-radius: 1rem;
      left: 4.5rem;

      .links-wrapper {
        margin-top: 1.6rem;
        position: relative;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 16rem;

        .links {
          cursor: pointer;
          flex: 0 0 auto;
        }
      }
    }
  }

  .creator-detail {
    padding: 2rem;
    padding-left: 23rem;

    .creator-name {
      font-weight: 600;
      font-size: 24px;
      line-height: 28.15px;
      margin-bottom: 1rem;
      text-transform: capitalize;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .category {
      font-size: 1.2rem;
      font-weight: 500;
      border: 1px solid #6e6af0;
      padding: 0.4rem 0.8rem;
      border-radius: 0.4rem;
      line-height: 11.73px;
      text-transform: capitalize;
    }

    .country {
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: 0.02em;
      border-bottom: 1px solid #e1e1e1;
      line-height: 16.42px;
      margin-bottom: 1rem;
      padding: 1rem 0;
      display: flex;
      text-transform: capitalize;
    }

    .about {
      font-size: 1.6rem;
      font-weight: 600;
      text-align: left;
      margin-bottom: 1rem;
    }

    .description {
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: 0.02em;
      line-height: 20px;
      letter-spacing: 2%;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}

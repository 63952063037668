.creators-card {
  transition: all 0.2s ease-out;
  cursor: pointer;
  margin-bottom: 2rem;
  border-radius: 2rem;
  position: relative;

  &:hover {
    border: 1px solid #6e6af0;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  }

  img {
    width: 100%;
    height: 18rem;
    object-fit: cover;
  }

  .name-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.7rem 1.6rem 1.9rem 1.6rem;

    .name {
      font-size: 1.4rem;
      font-weight: 500;
      max-width: 20rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: capitalize;
    }

    .type {
      padding: 0.4rem 0.9rem;
      border-radius: 1.6rem;
      border: 1px solid #5764AD;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.02em;
      color: #5764AD;
      text-transform: capitalize;
    }
  }
}
@mixin tag {
  font-weight: 400;
  font-size: 1.4rem;
  border-radius: 4rem;
  line-height: 13px;
  padding: 8px 20px;
  text-transform: capitalize;

  &.last-child {
    margin-left: 0.5rem;
  }
}


.space-post-detail-wrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
  }

  .post-info-container {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 10rem);
    overflow: auto;

    @media (min-width: 1200px) and (max-width: 1399px) {
      width: 52.4rem;
    }

    @media (min-width: 1400px) {
      width: 70.6rem;
    }

    .info-header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (min-width: 1200px) {
        padding-right: 2.4rem;
      }

      .icon-title-container {
        display: flex;
        align-items: center;

        .post-icon {
          width: 4.8rem;
          height: 4.8rem;

          .rs-avatar-image {
            width: 4.8rem;
            height: 4.8rem;
          }
        }

        .post-title {
          display: flex;
          flex-direction: column;
          margin-left: 1.2rem;

          .posted-by {
            font-size: 2rem;
          }

          .time-elapsed {
            font-size: 1rem;
          }
        }
      }

      .option-btn {
        background-color: transparent;
      }

      .tag-container {
        margin-left: auto;

        .tag-active {
          @include tag;
          background-color: #007E51;
        }

        .tag-inactive {
          @include tag;
          background-color: #E33342;
        }
      }
    }

    .post-image-container {
      margin-top: 1.6rem;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 48.1rem;

      @media (max-width: 700px) {
        height: 30rem;
      }

      @media (min-width: 1200px) and (max-width: 1399px) {
        width: 50rem;
        height: 36rem;
      }

      @media (min-width: 1400px) {
        width: 68.3rem;
        height: 48.1rem;
      }

      .post-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .empty-space-post-container {
      height: 48.1rem;
      margin-top: 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #e1e1e1;

      @media (max-width: 700px) {
        height: 30rem;
      }

      @media (min-width: 1200px) and (max-width: 1399px) {
        width: 50rem;
        height: 36rem;
      }

      @media (min-width: 1400px) {
        width: 68.3rem;
        height: 48.1rem;
      }
    }

    .like-comment-count {
      margin-top: 1.6rem;
      display: flex;
      align-items: center;
      position: relative;

      .reaction-wrapper {
        position: absolute;
        left: 2rem;
        bottom: 2.5rem;
      }

      .like-comment-status {
        display: flex;
        align-items: center;

        &:last-child {
          margin-left: 1.6rem;
        }

        .count {
          margin-left: 0.4rem;
        }
      }
    }

    .post-caption {
      margin-top: 1.6rem;
      font-size: 1.4rem;
      padding: 0 2.4rem 1.6rem 0;
      height: calc(100vh - 72rem);
      word-break: break-word;

      @media (max-width: 1200px) {
        border-bottom: 1px solid #e1e1e1;
        height: auto;
      }
    }
  }

  .post-comment-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: calc(100vh - 70rem);

    @media (max-width: 700px) {
      height: calc(100vh - 52rem);
    }

    @media (min-width: 1200px) {
      height: calc(100vh - 12rem);
      border-left: 1px solid #e1e1e1;
    }

    .comment-section {
      overflow-y: auto;
      margin-top: 2.4rem;

      @media (min-width: 1200px) {
        padding-left: 2.4rem;
        margin-top: 0;
      }

      .no-comments {
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: calc(100vh - 50rem);
        justify-content: center;

        @media (max-width: 1200px) {
          min-height: calc(100vh - 70rem);
        }

        .no-comment-text {
          font-size: 1.4rem;
          margin-top: 1.6rem;
          @include font-semi-bold;
        }
      }
    }

    .add-comment-container {
      width: 100%;
      position: absolute;
      bottom: -2.4rem;
      left: -24px;
      width: calc(100% + 4.8rem);

      @media (min-width: 1200px) {
        position: relative;
        bottom: auto;
        width: calc(100% + 2.4rem);
        left: auto;
      }

      .comment-textarea {
        width: 100%;
        border: none;
        border-radius: 0;
        padding: 2.1rem 4.5rem 2.2rem 2.4rem;
        box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
      }

      .send-btn {
        position: absolute;
        bottom: 2rem;
        right: 2.4rem;
        cursor: pointer;
      }
    }
  }
}
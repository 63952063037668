.space-page-cta {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  margin-bottom: 2.4rem;

  button {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.assigned-mods {
  border: 1px solid #e1e1e1;
  padding: 1.2rem 1.6rem;

  .rs-panel-body {
    display: flex;
    flex-direction: column;
  }

  .assigned-mods-count {
    font-size: 1.4rem;
  }

  .assigned-mods-name {
    margin-top: 2.4rem;
    margin-left: 0;
    padding: 0;
    background-color: transparent;
  }
}
.access-request-tournament-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;

  .access-request-button {
    margin-left: 2rem;
    padding: 1rem 2rem;
  }

  .active-button {
    background-color: #6e6af0 !important;
  }



  @media (max-width: 635px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2.4rem;
  }
}

.access-request-table-wrapper {
  border-radius: 1.2rem;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);

  .rs-tag {
    border-radius: 5px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    width: 78px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;

    .rs-tag-text {
      text-transform: capitalize;
    }
  }

  .rs-table-cell-content {
    display: flex;
    align-items: center;
  }
}

.rs-modal {
  max-width: 50rem;

  .access-approval-modal-body {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #575757;

    .modal-body-header {
      font-weight: 600;
      color: #252525;
    }
  }
}